#mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
}
#sidebar {
  overflow-y: auto;
  height: 100%;
  max-width: 15%;
  min-width: 175px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 1.8px rgba(0, 0, 0, 0.12),
    0px 3.2px 3.6px rgba(0, 0, 0, 0.08);
}

#content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

#contentheader{
  max-width: 830px;
  padding-left: 40px;
  padding-right: 40px;
}
#list {
  flex:1;
  overflow-y: auto; 
  padding: 10px;
  padding-left: 25px;
}

.list-container {
  max-width: 765px;
}
