.detail-page-content {
  margin-top: 24px;
  border-top: 1px solid rgba(105, 130, 155, 0.25);
  border-bottom: 1px solid rgba(105, 130, 155, 0.25);
}
.pivot-item-container {
  padding-left: 23px;
  background-color: #fbfbfb;
}
.scrollablePane-wrapper {
  min-height: 60vh;
  position: relative;
  max-height: inherit;
}

.armtemplate-content {
  overflow-wrap: break-word;
  white-space: pre-line;
  justify-content: center;
}

.license-content {
  max-width: 750px;
  text-align: justify;
  white-space: pre-line;
}
