.content-header-titlewapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.content-header-title {
  font-size: 18px;
  font-weight: bold;
}
.content-header-contributionLink {
  text-decoration: none;
}
.contributionLink-content {
  font-size: 12px;
  display: flex;
  text-decoration: none;
}

.contribution-link-text {
  margin-left: 12px;
}
.content-header-sortbywrappper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
}

.content-header-count {
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  color: #000000;
  font-size: 16px;
  line-height: normal;
}
