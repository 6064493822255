.icon-16px {
  width: 16px;
  height: 16px;
  vertical-align: baseline;
  fill: currentColor;
}

.icon-12px {
  width: 12px;
  height: 12px;
  vertical-align: baseline;
  margin-right: 4px;
  fill: currentColor;
}
