.headerbar {
  background-color: black;
  display: inline-block;
  width: 100%;
}

.auth-control {
  float: right;
}

.signin-button-container {
  text-align: center;
  padding: 20px 16px;
}
